import React, {Component} from "react";
import _ from "lodash";
import {Alert, Card, CardBody, CardHeader, Container, Row} from "reactstrap";
import Toastr from "toastr";
import {Link, Redirect} from "react-router-dom";
import AuthService from "./AuthService";
import Axios from "../helpers/Axios";
// import {CODENAME_APP} from '../config';
import Header from "../components/Headers/BlankHeader";

const Auth = new AuthService();

export default function withAuth(AuthComponent) {

  return class Authenticated extends Component {

    state = {
      // isLoginValid: null,
      isRedirected: false,
      isTokenValid: null,
      profile: null,
    };

    componentDidMount() {
      // console.log('withAuth/componentDidMount');
      // console.log(this.props.query)
      // if (!Auth.loggedIn()) {
      //   Router.push("/");
      // }
      this.getProfile();
    }

    getProfile() {
      Axios.get(`/arsip-surat-ppk/profile`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`
        }
      })
        .then(res => {
          this.setState({
            isTokenValid: true,
            // isLoginValid: true,
            profile: res.data,
          });
        })
        .catch(error => {
          // https://stackoverflow.com/questions/39153080/how-can-i-get-the-status-code-from-an-http-error-in-axios#:~:text=In%20order%20to%20get%20the,(res%20%3D%3E%20%7B%20console.
          if (_.isEqual(error.response.status, 401)) {
            console.log('401')
            this.setState({
              isTokenValid: false,
            });
          }
        });
    }

    onClickLogout = () => {
      Toastr.info('Relogin...');
      Auth.logout();
      this.setState({isRedirected: true});
    };

    render() {

      // console.log(this.state);
      // console.log(CODENAME_APP);

      if (!_.isNull(this.state.profile)) {
        if (!this.state.profile.isArsipSuratPpkUser) {
          return (
            <>Application Denied!</>
          )
        }
      }

      if (!_.isNull(this.state.profile)) {
        let roles = this.state.profile.roles;
        let url = this.props.match.path;
        if (!roles.includes(url)) {
          return (
            <>
              <Container>
                <div className="header pb-8 pt-5 pt-md-8">
                  <Container fluid>
                    <div className="header-body">
                      <Alert color="warning">Forbidden!</Alert>
                    </div>
                  </Container>
                </div>
              </Container>
            </>
          )
        }
      }

      if (this.state.isRedirected) {
        return <Redirect to="/"/>
      }

      if (_.isBoolean(this.state.isTokenValid) && !this.state.isTokenValid) {
        return (
          <>
            <Container>
              <div className="header pb-8 pt-5 pt-md-8">
                <Container fluid>
                  <div className="header-body">
                    <Link to={"#"}>
                      <Alert color="danger" onClick={this.onClickLogout}>Session anda sudah berakhir!. Klik disini untuk
                        login ulang</Alert>
                    </Link>
                  </div>
                </Container>
              </div>
            </Container>
          </>
        )
      }

      if (_.isBoolean(this.state.isTokenValid)) {
        return (
          <AuthComponent
            {...this.props}
            auth={Auth}
            token={Auth.getToken()}
            profile={this.state.profile}
          />
        );
      }

      return (
        <>
          <Header/>
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                  </CardHeader>
                  <CardBody>
                    Loading...
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )
    }
  };
}
