import React, {Component} from 'react';
import _ from 'lodash';

class PaginationInfo extends Component {

  render() {
    return (
      <div>
        {this.props.totalItemsCount} records,
        page : {this.props.activePage} of {_.ceil(this.props.totalItemsCount / this.props.itemsCountPerPage)}
      </div>
    );
  }
}

export default PaginationInfo;
