import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row
} from "reactstrap";
import Select from 'react-select';
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import Switch from "react-switch";
import _ from "lodash";
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";

class GroupsEdit extends React.Component {

  state = {
    is_update_data: false,
    is_valid_data: true,
    id: '',
    code: '',
    code_exist: false,
    name: '',
    description: '',
    is_active: true,
    roles: null,
    roles_options: [],
  };

  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
    this.handleChangeRoles = this.handleChangeRoles.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // console.log(this.props);
    this.getData(this.props.match.params.id);
    this.rolesOptions();
  }

  getData(id) {
    Axios.get(`/arsip-surat-ppk/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({
          // _data: res.data,
          id: res.data.id,
          code: res.data.code,
          name: res.data.name,
          description: _.isNull(res.data.description) ? '' : res.data.description,
          is_active: res.data.isActive,
        });

        // setup roles
        if (_.isArray(res.data.arsipSuratPpkRoles)) {
          const rolesSetup = [];
          res.data.arsipSuratPpkRoles.map((d, i) => {
            if (!_.isNull(d.uid)) {
              rolesSetup.push({
                value: d.id,
                label: `[${d.code}] ${d.name}`,
              })
            }
            return rolesSetup;
          });
          this.setState({roles: rolesSetup});
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  rolesOptions = () => {
    Axios.get(`/get-roles?format=select_options&applications=arsip-surat-ppk&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data.result)
        this.setState({roles_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  codeValidation = (code) => {
    Axios.get(`/is-kegiatan-surat-exist/${code}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data.result)
        this.setState({
          code_exist: res.data.result,
          is_valid_data: !res.data.result
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeCode = (obj) => {
    let code = obj.target.value;
    this.setState({code});

    if (code.length >= 2) {
      this.codeValidation(code);
    }
  };

  handleChangeName = (obj) => {
    this.setState({name: obj.target.value});
  };

  handleChangeDescription = (e) => {
    this.setState({description: e.target.value});
  };

  handleChangeIsActive(is_active) {
    this.setState({is_active});
  }

  handleChangeRoles = (roles) => {
    this.setState({roles});
  };

  onSubmit = () => {
    Toastr.info('Submit data');
    let rolesString = "";
    this.state.roles.map((d, i) => {
      rolesString += `${(d.value)}`;
      if (i < (this.state.roles.length - 1)) {
        rolesString += ',';
      }
      return rolesString;
    });

    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'PATCH',
      url: `/arsip-surat-ppk/groups/${this.state.id}`,
      data: {
        code: this.state.code,
        name: this.state.name,
        roles: `[${rolesString}]`,
        isActive: this.state.is_active ? 'true' : 'false',
        description: this.state.description,
      }
    })
      .then(res => {
        console.log(res.data);
        Toastr.success('Success!');
        this.setState({is_removed_data: true});
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  render() {
    console.log(this.state);

    if (this.state.is_update_data) {
      return <Redirect push to={`/admin/groups`}/>
    }

    let buttonSubmitDisabled = true;
    if (this.state.is_valid_data) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <Header name={`Groups / Edit`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/groups`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Code
                            </label>
                            <Input
                              readOnly
                              required
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.code}
                              onChange={this.handleChangeCode}
                              placeholder="Code"
                              type="text"
                            />
                            {this.state.code_exist ?
                              <FormText color={'danger'}>Oh noes! that name is already taken</FormText> : null}
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.name}
                              onChange={this.handleChangeName}
                              placeholder="Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Status Active : </label> <Switch
                        onChange={this.handleChangeIsActive}
                        checked={this.state.is_active}
                      />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          required
                          className="form-control-alternative"
                          minLength={3}
                          value={this.state.description}
                          onChange={this.handleChangeDescription}
                          placeholder="Description"
                          type="text"
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Role(s)</label>
                        <Select
                          isMulti
                          value={this.state.roles}
                          onChange={this.handleChangeRoles}
                          options={this.state.roles_options}
                        />
                      </FormGroup>
                    </div>

                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    color='primary'
                    disabled={buttonSubmitDisabled}
                    onClick={this.onSubmit}
                  >Save</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(GroupsEdit)
