import React, {Component} from 'react';
import _ from "lodash";
import {Button, Table} from 'reactstrap';
import DatetimeFormat from "../Utils/DatetimeFormat";
import IconBoolean from "../Utils/IconBoolean";

class Kegiatan extends Component {

  render() {

    if (_.isNull(this.props.data)) {
      return <>loading...</>;
    }

    return (
      <>
        <Table bordered responsive>
          <tbody>
          <tr>
            <td>Code :</td>
            <td><Button color={`default`}>{this.props.data.code}</Button></td>
          </tr>
          <tr>
            <td>Code Alias:</td>
            <td><code><h2>{this.props.data.codeAlias}</h2></code></td>
          </tr>
          <tr>
            <td>Name :</td>
            <td>{this.props.data.name}</td>
          </tr>
          <tr>
            <td>Description :</td>
            <td>{this.props.data.description}</td>
          </tr>
          <tr>
            <td>Is Active :</td>
            <td><IconBoolean value={this.props.data.isActive}/></td>
          </tr>
          <tr>
            <td>Date (create / update) :</td>
            <td>
              <small>
                <DatetimeFormat
                  value={this.props.data.createdAt}/> / {_.isEqual(this.props.data.createdAt, this.props.data.updatedAt) ? null :
                <DatetimeFormat value={this.props.data.updatedAt}/>}
              </small>
            </td>
          </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

Kegiatan.propTypes = {};

export default Kegiatan;
