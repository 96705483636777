import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row,} from "reactstrap";
import _ from 'lodash';
import Toastr from 'toastr';

import Header from "components/Headers/ProfileHeader.js";
import Axios from "helpers/AxiosWithoutToken";
import WithAuth from "utils/WithAuth";
import AuthService from 'utils/AuthService.js';

const auth = new AuthService();

class Profile extends React.Component {

  state = {
    id: '',
    username: '',
    fullName: '',
    groups: null,
  };

  constructor(props) {
    super(props);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
  }

  componentWillMount() {
    // console.log(this.props.profile);
    this.setData(this.props.profile);
  }

  setData(profile) {
    this.setState({
      id: profile.id,
      username: profile.username,
      fullName: _.isNull(profile.fullName) ? '' : profile.fullName,
      groups: _.isNull(profile.arsipSuratPpkGroup) ? null : profile.arsipSuratPpkGroup,
    });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeFullName = (e) => {
    this.setState({fullName: e.target.value});
  };

  onSubmit = () => {
    Toastr.info('Update!');
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'PATCH',
      url: `/arsip-surat-ppk/users/${this.state.id}`,
      data: {
        username: this.state.username,
        fullName: this.state.fullName,
      }
    })
      .then(res => {
        // console.log(res);
        Toastr.success('Success!');
      })
      .then(res => {
        auth.refreshProfile();
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Header profile={this.props.profile}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Username
                            </label>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="text"
                              value={this.state.username}
                              placeholder="Username"/>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Full Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.fullName}
                              onChange={this.handleChangeFullName}
                              placeholder="Full Name"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Groups : {_.isNull(this.state.groups) ? null : `${this.state.groups.name}`}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <Row>
                            <Col lg="12">
                              <Button
                                color='primary'
                                onClick={this.onSubmit}
                              >Save</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(Profile)
