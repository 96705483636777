import React, {Component} from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import _ from 'lodash';
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import Header from "components/Headers/SimpleHeader";
import PaginationInfo from 'components/Utils/PaginationInfo';
import DatetimeFormat from 'components/Utils/DatetimeFormat';
import DatetimeFormatV2 from 'components/Utils/DatetimeFormatV2';
import DataSuratUlpPopUp from 'components/PopUp/DataSuratUlp';
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";

class DataSuratUlp extends React.Component {

  state = {
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: [],
    search: '',
    filter: {},
  };

  constructor(props) {
    super(props);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    // Delay action 1 seconds
    this.onChangeDebounced = _.debounce(this.onChangeDebounced, 1000)
  }

  componentWillMount() {
    // console.log(this.props);
    this._getDataGrid(1, this.state.filter);
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber}, () => this._getDataGrid(this.state.activePage, this.state.filter));
  };

  _getDataGrid = (page, filter = null) => {
    // console.log(filter);
    let newUrl = `/arsip-surat-ppk/data-surat?group=arsip-surat-ppk-ulp&page=${page}`;

    // filter
    if (_.isObject(filter)) {

      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          newUrl += `&search=${filter.search}`;
        }
      }

      // orderBy & orderOrientation
      if (_.has(filter, 'orderBy')) {
        if (_.has(filter, 'orderOrientation')) {
          newUrl += `&sort=${JSON.stringify({
            [filter.orderBy]: filter.orderOrientation
          })}`;
        } else {
          newUrl += `&sort=${JSON.stringify({
            [filter.orderBy]: 'DESC'
          })}`;
        }
      }

      // pageSize
      if (_.has(filter, 'pageSize')) {
        newUrl += `&page_size=${filter.pageSize}`;
      }

    } else {
      newUrl += `&page_size=7`
    }

    // groups=admin, view all
    // console.log(this.props.profile)
    if (!_.isEqual(this.props.profile.arsipSuratPpkGroup.code, 'arsip-surat-ppk-admin')) {
      newUrl += `&created-by=${this.props.profile.id}`
    }

    // console.log(newUrl);

    Axios.get(`${newUrl}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({
          data: res.data._embedded.arsip_surat_ppk_data_surat,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleInputChange = (obj) => {
    this.setState({
      search: obj.target.value,
      filter: {search: obj.target.value}
    });
    this.onChangeDebounced(obj)
  };

  onChangeDebounced = (e) => {
    // Delayed logic goes here
    this._getDataGrid(1, {search: this.state.search});
  };


  render() {
    console.log(this.state);
    return (
      <>
        <Header name={`Data Surat ULP`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Form
                    onSubmit={this.handleOnSubmit}
                    className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto">
                    <FormGroup className="mb-0">
                      <Link to={`/admin/data-surat-ulp-add`}>
                        <Button className={'primary'}>Add</Button>
                      </Link>
                      <InputGroup style={{border: '1px solid #E1E1E1'}} className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.handleInputChange}
                          value={this.state.search}
                          placeholder="Search..."
                          type="text"/>
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">Nomer Surat</th>
                    <th scope="col">Tanggal Surat</th>
                    <th scope="col">Kode Hal</th>
                    <th scope="col">Pecipta</th>
                    <th scope="col">Created At<br/>Updated At</th>
                    <th scope="col"/>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.data.length === 0 ? <tr>
                    <td colSpan={5}><code>Data Not Found!</code></td>
                  </tr> : null}
                  {this.state.data.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td><DataSuratUlpModal
                          key={i}
                          data={d}
                          token={this.props.token}
                        />
                        </td>
                        <td>{_.isNull(d.tanggalSurat) ? '-' : <DatetimeFormatV2 value={d.tanggalSurat}/>}</td>
                        <td>{_.has(d, 'kodeHalUlp') ? `[${d.kodeHalUlp.code}] ${d.kodeHalUlp.name}` : null}</td>
                        <td>{_.has(d, 'createdBy') ? `[${d.createdBy.username}] ${d.createdBy.fullName}` : null}</td>
                        <td>
                          <small><DatetimeFormat value={d.createdAt}/><br/>
                            {_.isEqual(d.createdAt, d.updatedAt) ? '-' : <DatetimeFormat value={d.updatedAt}/>}
                          </small>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                            >
                              <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem>
                                <Link to={`/admin/data-surat-ulp-edit/${d.id}`}>
                                  Edit
                                </Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={this.state.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                  <PaginationInfo
                    activePage={this.state.activePage}
                    totalItemsCount={this.state.totalItemsCount}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(DataSuratUlp);

class DataSuratUlpModal extends Component {

  state = {
    modal_show: false,
  };

  constructor(props) {
    super(props);
    this.toggleModalDataSurat = this.toggleModalDataSurat.bind(this);
  }

  toggleModalDataSurat() {
    this.setState({modal_show: !this.state.modal_show});
  }

  render() {
    return (
      <div>
        <a href={`#${this.props.data.nomerSurat}`}>
          <h2 onClick={this.toggleModalDataSurat}><code>{this.props.data.nomerSurat}</code></h2>
        </a>
        <Modal
          size={'lg'}
          isOpen={this.state.modal_show}
          toggle={this.toggleModalDataSurat}>
          <ModalHeader toggle={this.toggleModalDataSurat}>Data Surat ULP</ModalHeader>
          <ModalBody>
            <DataSuratUlpPopUp
              token={this.props.token}
              data={this.props.data}
            />
          </ModalBody>
          <ModalFooter>
            <Link to={`/admin/data-surat-ulp-detail/${this.props.data.id}`}>
              <Button color="success">View</Button>
            </Link>
            <Link to={`/admin/data-surat-ulp-edit/${this.props.data.id}`}>
              <Button color="info">Edit</Button>
            </Link>
            <Button color="primary" onClick={this.toggleModalDataSurat}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
