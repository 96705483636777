/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Blank from "views/pages/Blank.js";
import Login from "views/pages/Login";
import Dashboard from "views/pages/Dashboard";
import Profile from "views/pages/Profile";
import Password from "views/pages/Password";

import KodeHalPpk from "views/pages/KodeHalPpk";
import KodeHalPpkAdd from "views/pages/KodeHalPpkAdd";
import KodeHalPpkEdit from "views/pages/KodeHalPpkEdit";
import KodeHalPpkDelete from "views/pages/KodeHalPpkDelete";

import KodeHalUlp from "views/pages/KodeHalUlp";
import KodeHalUlpAdd from "views/pages/KodeHalUlpAdd";
import KodeHalUlpEdit from "views/pages/KodeHalUlpEdit";
import KodeHalUlpDelete from "views/pages/KodeHalUlpDelete";

import Kegiatan from "views/pages/Kegiatan";
import KegiatanAdd from "views/pages/KegiatanAdd";
import KegiatanEdit from "views/pages/KegiatanEdit";
import KegiatanDelete from "views/pages/KegiatanDelete";

import Tahun from "views/pages/Tahun";
import TahunEdit from "views/pages/TahunEdit";

import DataSuratPpk from "views/pages/DataSuratPpk";
import DataSuratPpkAdd from "views/pages/DataSuratPpkAdd";
import DataSuratPpkDetail from "views/pages/DataSuratPpkDetail";
import DataSuratPpkEdit from "views/pages/DataSuratPpkEdit";

import DataSuratUlp from "views/pages/DataSuratUlp";
import DataSuratUlpAdd from "views/pages/DataSuratUlpAdd";
import DataSuratUlpDetail from "views/pages/DataSuratUlpDetail";
import DataSuratUlpEdit from "views/pages/DataSuratUlpEdit";

import Groups from "views/pages/Groups";
import GroupsEdit from "views/pages/GroupsEdit";

import Users from "views/pages/Users";
import UsersAdd from "views/pages/UsersAdd";
import UsersEdit from "views/pages/UsersEdit";
import UsersDelete from "views/pages/UsersDelete";


const routes = [
  {
    hidden: true,
    path: "/login",
    component: Login,
    layout: "/auth"
  },
  {
    hidden: true,
    path: "/profile",
    component: Profile,
    layout: "/admin"
  },
  {
    hidden: true,
    path: "/change-password",
    component: Password,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Master",
    icon: "ni ni-ungroup text-orange",
    state: "masterCollapse",
    views: [
      // Kode Hal PPK
      {
        path: "/kode-hal-ppk",
        name: "Kode Hal PPK",
        miniName: "PPK",
        component: KodeHalPpk,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ppk-add",
        hidden: true,
        name: "Kode Hal PPK - Add",
        component: KodeHalPpkAdd,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ppk-edit/:id",
        hidden: true,
        name: "Kode Hal PPK / Edit",
        component: KodeHalPpkEdit,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ppk-delete/:id",
        hidden: true,
        name: "Kode Hal PPK / Delete",
        component: KodeHalPpkDelete,
        layout: "/admin"
      },

      // Kode Hal ULP
      {
        path: "/kode-hal-ulp",
        name: "Kode Hal ULP",
        miniName: "ULP",
        component: KodeHalUlp,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ulp-add",
        hidden: true,
        name: "Kode Hal ULP - Add",
        component: KodeHalUlpAdd,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ulp-edit/:id",
        hidden: true,
        name: "Kode Hal ULP / Edit",
        component: KodeHalUlpEdit,
        layout: "/admin"
      },
      {
        path: "/kode-hal-ulp-delete/:id",
        hidden: true,
        name: "Kode Hal ULP / Delete",
        component: KodeHalUlpDelete,
        layout: "/admin"
      },

      // Kegiatan
      {
        path: "/kegiatan",
        name: "Kegiatan",
        miniName: "KG",
        component: Kegiatan,
        layout: "/admin"
      },
      {
        path: "/kegiatan-add",
        hidden: true,
        name: "Kegiatan Add",
        component: KegiatanAdd,
        layout: "/admin"
      },
      {
        path: "/kegiatan-edit/:id",
        hidden: true,
        name: "Kegiatan Edit",
        component: KegiatanEdit,
        layout: "/admin"
      },
      {
        path: "/kegiatan-delete/:id",
        hidden: true,
        name: "Kegiatan Delete",
        component: KegiatanDelete,
        layout: "/admin"
      },

      // Tahun
      {
        path: "/tahun",
        name: "Tahun",
        miniName: "TP",
        component: Tahun,
        layout: "/admin"
      },
      {
        path: "/tahun-edit/:id",
        hidden: true,
        name: "Tahun Edit",
        component: TahunEdit,
        layout: "/admin"
      },
    ]
  },

  {
    collapse: true,
    name: "Data Surat",
    icon: "fa fa-box text-info",
    state: "DataSuratCollapse",
    views: [
      // PPK
      {
        path: "/data-surat-ppk",
        name: "PPK",
        miniName: "PPK",
        component: DataSuratPpk,
        layout: "/admin"
      },
      {
        path: "/data-surat-ppk-add",
        hidden: true,
        name: "Data Surat PPK - Add",
        component: DataSuratPpkAdd,
        layout: "/admin"
      },
      {
        path: "/data-surat-ppk-detail/:id",
        hidden: true,
        name: "Data Surat PPK - Detail",
        component: DataSuratPpkDetail,
        layout: "/admin"
      },
      {
        path: "/data-surat-ppk-edit/:id",
        hidden: true,
        name: "Data Surat PPK - Edit",
        component: DataSuratPpkEdit,
        layout: "/admin"
      },

      // ULP
      {
        path: "/data-surat-ulp",
        name: "ULP",
        miniName: "ULP",
        component: DataSuratUlp,
        layout: "/admin"
      },
      {
        path: "/data-surat-ulp-add",
        hidden: true,
        name: "Data Surat ULP - Add",
        component: DataSuratUlpAdd,
        layout: "/admin"
      },
      {
        path: "/data-surat-ulp-detail/:id",
        hidden: true,
        name: "Data Surat ULP - Detail",
        component: DataSuratUlpDetail,
        layout: "/admin"
      },
      {
        path: "/data-surat-ulp-edit/:id",
        hidden: true,
        name: "Data Surat ULP - Edit",
        component: DataSuratUlpEdit,
        layout: "/admin"
      },
    ]
  },

  // {
  //   path: "/data-surat",
  //   name: "Data Surat",
  //   icon: "fa fa-box text-info",
  //   component: DataSurat,
  //   layout: "/admin"
  // },

  // User Manager
  {
    collapse: true,
    name: "Users Manager",
    icon: "fa fa-users text-warning",
    state: "userManagerCollapse",
    views: [
      // Groups
      {
        path: "/groups",
        name: "Groups",
        miniName: "GR",
        component: Groups,
        layout: "/admin"
      },
      {
        path: "/groups-edit/:id",
        hidden: true,
        name: "Groups Edit",
        component: GroupsEdit,
        layout: "/admin"
      },
      // users
      {
        path: "/users",
        name: "User",
        miniName: "US",
        component: Users,
        layout: "/admin"
      },
      {
        path: "/users-add",
        hidden: true,
        name: "Users Add",
        component: UsersAdd,
        layout: "/admin"
      },
      {
        path: "/users-edit/:id",
        hidden: true,
        name: "Users Edit",
        component: UsersEdit,
        layout: "/admin"
      },
      {
        path: "/users-delete/:id",
        hidden: true,
        name: "Users Delete",
        component: UsersDelete,
        layout: "/admin"
      },
    ]
  }
];

export default routes;
