import React, {Component} from 'react';
import _ from "lodash";
import {Table} from 'reactstrap';
import DatetimeFormat from "../Utils/DatetimeFormat";
import IconBoolean from "../Utils/IconBoolean";

class Groups extends Component {

  render() {

    if (_.isNull(this.props.data)) {
      return <>loading...</>;
    }

    return (
      <>
        <Table bordered responsive>
          <tbody>
          <tr>
            <td>Code :</td>
            <td><h2><code>{this.props.data.code}</code></h2></td>
          </tr>
          <tr>
            <td>Name :</td>
            <td>{this.props.data.name}</td>
          </tr>
          <tr>
            <td>Description :</td>
            <td>{this.props.data.description}</td>
          </tr>
          <tr>
            <td>Is Active :</td>
            <td><IconBoolean value={this.props.data.isActive}/></td>
          </tr>
          <tr>
            <td>Role(s) :</td>
            <td>{_.has(this.props.data, 'arsipSuratPpkRoles') ?
              <RolesLooping data={this.props.data.arsipSuratPpkRoles}/> : '-'}</td>
          </tr>
          <tr>
            <td>Date (create / update) :</td>
            <td>
              <small>
                <DatetimeFormat
                  value={this.props.data.createdAt}/> / {_.isEqual(this.props.data.createdAt, this.props.data.updatedAt) ? null :
                <DatetimeFormat value={this.props.data.updatedAt}/>}
              </small>
            </td>
          </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

Groups.propTypes = {};

export default Groups;

class RolesLooping extends Component {

  render() {
    return (
      <>
        <ul>
          {this.props.data.map((d, i) => {
            return (
              <li key={i}>
                [{d.code}] {d.name}
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}
