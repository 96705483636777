import React from 'react';
import _ from 'lodash';
import {format, parseISO} from 'date-fns';

class DatetimeFormat extends React.Component {

  render() {

    let result = null;
    if (!_.isUndefined(this.props.value) || !_.isEmpty(this.props.value)) {
      result = parseISO(this.props.value);
    }

    return (
      <>
        {_.isDate(result) ? format(result, "MM-dd-yy HH:mm:ss") : ''}
      </>
    );
  }

}

export default DatetimeFormat;
