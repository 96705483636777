/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";

class ChangePasswordHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "200px",
            backgroundImage:
              'url("' + require("assets/img/astronomy.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          <span className="mask bg-gradient-info opacity-7"/>
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col
                // lg="10"
                md="12">
                <h1 className="display-8 text-white">Change Password</h1>
                {/*<p className="text-white mt-0 mb-12">*/}
                {/*  This is your profile page. You can see the progress you've*/}
                {/*</p>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ChangePasswordHeader;
