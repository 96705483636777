import React, {Component} from 'react';
import _ from "lodash";
import {Table} from 'reactstrap';
import DatetimeFormat from "../Utils/DatetimeFormat";
import IconBoolean from "../Utils/IconBoolean";

class Users extends Component {

  render() {

    if (_.isNull(this.props.data)) {
      return <>loading...</>;
    }

    return (
      <>
        <Table bordered responsive>
          <tbody>
          <tr>
            <td>Username :</td>
            <td><h2><code>{this.props.data.username}</code></h2></td>
          </tr>
          <tr>
            <td>Full Name (Nama Pejabat) :</td>
            <td>{this.props.data.fullName}</td>
          </tr>
          <tr>
            <td>Group :</td>
            <td>{_.has(this.props.data, 'arsipSuratPpkGroup') ? `[${this.props.data.arsipSuratPpkGroup.code}] ${this.props.data.arsipSuratPpkGroup.name}` : '-'}</td>
          </tr>
          <tr>
            <td>Kegiatan :</td>
            <td>{_.has(this.props.data, 'kegiatan') ? <KegiatanLooping data={this.props.data.kegiatan}/> : '-'}</td>
          </tr>
          <tr>
            <td>Is Active :</td>
            <td><IconBoolean value={this.props.data.isActive}/></td>
          </tr>
          <tr>
            <td>Date (create / update) :</td>
            <td>
              <small>
                <DatetimeFormat
                  value={this.props.data.createdAt}/> / {_.isEqual(this.props.data.createdAt, this.props.data.updatedAt) ? null :
                <DatetimeFormat value={this.props.data.updatedAt}/>}
              </small>
            </td>
          </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

Users.propTypes = {};

export default Users;

class KegiatanLooping extends Component {

  render() {
    return (
      <>
        <ul>
          {this.props.data.map((d, i) => {
            return (
              <li key={i}>
                [{d.code}] {d.name}
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}
