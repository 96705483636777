import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Link} from 'react-router-dom';

import Axios from "helpers/AxiosWithoutToken";
import PDFViewer from 'components/PDFViewer/PDFViewer';
import PDFJSBackend from 'backends/pdfjs';

export class ReadFileAttachment extends Component {

  state = {
    modalView: false,
  };

  constructor(props) {
    super(props);

    this.toggleView = this.toggleView.bind(this);
  }

  toggleView() {
    this.setState({
      modalView: !this.state.modalView,
    });
  }

  render() {

    // console.log(this.props.data);

    let itemGrid = null;
    let itemView = null;

    if (this.props.data.fileType === 'application/pdf') {
      itemGrid = (
        <>
          {this.props.data.fileName}
        </>
      );

      itemView = (
        <>
          <div
            style={{overflow: 'scroll', height: 400}}
          >
            <PDFViewer
              backend={PDFJSBackend}
              src={`${Axios.defaults.baseURL}/arsip-surat-ppk/file-attachment-read/${this.props.data.id}/${this.props.token}`}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <Link to={'#'} onClick={this.toggleView}><code>{itemGrid}</code></Link>
        <Modal
          size={'lg'}
          isOpen={this.state.modalView}
          toggle={this.toggleView}>
          <ModalHeader toggle={this.toggleView}>File</ModalHeader>
          <ModalBody>
            {itemView}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleView}>
              <a
                href={`${Axios.defaults.baseURL}/arsip-surat-ppk/file-attachment-read/${this.props.data.id}/${this.props.token}`}
                target='_blank'
                rel='noopener noreferrer'
              >Download</a>
            </Button>
            <Button color="primary" onClick={this.toggleView}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

}
