import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Container, Row} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import _ from 'lodash';
import Swal from "sweetalert2";
import Header from "components/Headers/SimpleHeader";
import KodeHalUlpPopUp from "components/PopUp/KodeHalUlp";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";

class KodeHalUlpDelete extends React.Component {

  state = {
    is_removed_data: false,
    is_allow_remove_data: false,
    _data: null,
  };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // console.log(this.props.match.params.id);
    this.getData(this.props.match.params.id);
    this.isAllowRemove(this.props.match.params.id);
  }

  isAllowRemove(id) {
    Axios.get(`/arsip-surat-ppk/is-allow-remove-kode-hal-ulp/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data);
        this.setState({
          is_allow_remove_data: res.data.result,
        });
      })
      .catch(err => {
        console.error(err);
      });

  }

  getData(id) {
    Axios.get(`/arsip-surat-ppk/kode-hal-ulp/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({
          _data: res.data,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  removeData(id) {
    // console.log(id);
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'DELETE',
      url: `/arsip-surat-ppk/kode-hal-ulp/${id}`,
    })
      .then(res => {
        Toastr.success('Success!');
        this.setState({is_removed_data: true});
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  }

  onSubmit = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.removeData(this.state._data.id);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
        this.setState({is_removed_data: true});
      }
    })
  };

  render() {
    // console.log(this.state);

    if (_.isNull(this.state._data)) {
      return <>Loading...</>
    }

    if (this.state.is_removed_data) {
      return <Redirect push to={`/admin/kode-hal-ulp`}/>
    }

    return (
      <>
        <Header name={`Kode Hal / Delete`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/kode-hal-ulp`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <KodeHalUlpPopUp data={this.state._data}/>
                </CardBody>
                <CardFooter>
                  <Button
                    color='danger'
                    disabled={!this.state.is_allow_remove_data}
                    onClick={this.onSubmit}
                  >Delete</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(KodeHalUlpDelete)
