import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {Col, Container, Row} from "reactstrap";

class BlankHeader extends React.Component {
  render() {
    // console.log(this.props)
    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

BlankHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default BlankHeader;
