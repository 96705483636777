import React from 'react';
import _ from 'lodash';
import {format, parseISO} from 'date-fns';
import id from 'date-fns/locale/id';

class DatetimeFormatV2 extends React.Component {

  render() {

    let result = null;
    if (!_.isUndefined(this.props.value) || !_.isEmpty(this.props.value)) {
      result = parseISO(this.props.value);
    }

    return (
      <>
        {_.isDate(result) ? format(result, 'dd, MMMM yyyy', {locale: id}) : ''}
      </>
    );

  }
}

export default DatetimeFormatV2;
