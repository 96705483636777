import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, FormText, Input, Row} from "reactstrap";
import _ from 'lodash';
import Toastr from 'toastr';

import Header from "components/Headers/ChangePasswordHeader.js";
import Axios from "helpers/AxiosWithoutToken";
import WithAuth from "utils/WithAuth";

class Profile extends React.Component {

  state = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',

    passwordValid: null,
    passwordMinLength: 3,
  };

  constructor(props) {

    super(props);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeOldPassword = this.handleChangeOldPassword.bind(this);
    this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
    this.handleChangeConfirmNewPassword = this.handleChangeConfirmNewPassword.bind(this);
  }

  componentWillMount() {
    // const uid = authService.getProfile().uid;
    // console.log(this.props.profile);
  }

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeOldPassword = (e) => {
    this.setState({old_password: e.target.value});
  };

  handleChangeNewPassword = (obj) => {
    let new_password = obj.target.value;
    this.setState({new_password});
    if (new_password.length >= this.state.passwordMinLength) {
      this.setState({passwordValid: true});
    } else {
      this.setState({passwordValid: false});
    }
  };

  handleChangeConfirmNewPassword = (e) => {
    this.setState({confirm_new_password: e.target.value});
  };

  onSubmit = () => {
    Toastr.info('Change password!');
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'POST',
      url: `/change-password`,
      data: {
        old_password: this.state.old_password,
        new_password: this.state.new_password,
      }
    })
      .then(res => {
        Toastr.success('Success!');
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };


  render() {

    // console.log(this.state);

    let buttonSubmitDisabled = true;
    if (!_.isEmpty(this.state.old_password)
      && this.state.passwordValid
      && _.isEqual(this.state.new_password, this.state.confirm_new_password)
    ) {
      buttonSubmitDisabled = false;
    }
    return (
      <>
        <Header profile={this.props.profile}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Username
                            </label>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="text"
                              value={this.props.profile.username}
                              placeholder="Username"/>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Old Password
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.old_password}
                              onChange={this.handleChangeOldPassword}
                              placeholder="Old Password"/>
                            {_.isEmpty(this.state.old_password) ? <FormText color={'info'}>Required</FormText> : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              New Password
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.new_password}
                              onChange={this.handleChangeNewPassword}
                              placeholder="New Password"/>
                            {_.isBoolean(this.state.passwordValid) ?
                              <FormText color={'info'}>Required! min 3 char</FormText> : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Confirm New Password
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.confirm_new_password}
                              onChange={this.handleChangeConfirmNewPassword}
                              placeholder="Confirm New Password"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <Row>
                            <Col lg="12">
                              <Button
                                color='primary'
                                disabled={buttonSubmitDisabled}
                                onClick={this.onSubmit}
                              >Save</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(Profile)
