import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Container, Row} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";
import UsersDetail from 'components/PopUp/Users';
import DataNotFound from 'components/Utils/DataNotFound';

class UsersDelete extends React.Component {

  state = {
    is_allow_remove: false,
    is_removed_data: false,
    data: null,
    alert: null
  };

  componentWillMount() {
    // console.log(this.props);
    let id = this.props.match.params.id;
    this.getData(id);
    this.isAllowRemove(id);
  }

  getData(id) {
    Axios.get(`/arsip-surat-ppk/users/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({data: res.data})
      })
      .catch(err => {
        console.error(err);
      });
  }

  isAllowRemove(id) {
    Axios.get(`/arsip-surat-ppk/is-allow-remove-user/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data.result)
        this.setState({is_allow_remove: res.data.result})
      })
      .catch(err => {
        console.error(err);
      });
  }

  notify = type => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Hapus User
          </span>
          <span data-notify="message">
            User telah dihapus
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  deleteConfirm = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          // style={{display: "block", marginTop: "-100px"}}
          title="Hapus User"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{display: "flex"}}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => this.deleteAction()}
          onCancel={() => this.hideConfirm()}
          confirmBtnBsStyle="default"
          confirmBtnText="Ya, Hapus!"
          btnSize=""
        >
          Anda yakin?
        </ReactBSAlert>
      )
    });
  };

  deleteAction = () => {
    // console.log('deleteAction');
    this.notify("warning");
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'DELETE',
      url: `/arsip-surat-ppk/users/${this.state.data.id}`,
    })
      .then(res => {
        // console.log(res.data);
        this.setState({
          alert: null,
          is_removed_data: true,
        });
      })
      .catch(err => {
        console.error('ERROR!', err);
      })
  };

  hideConfirm = () => {
    this.setState({alert: null});
  };

  render() {
    console.log(this.state);

    if (this.state.is_removed_data) {
      return <Redirect push to={`/admin/users`}/>
    }

    if (_.isNull(this.state.data)) {
      return <DataNotFound/>
    }

    return (
      <>
        {this.state.alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert"/>
        </div>
        <Header name={`Users / Delete`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/users`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <UsersDetail data={this.state.data}/>
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={!this.state.is_allow_remove}
                    color="warning"
                    onClick={this.deleteConfirm}>
                    Delete
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(UsersDelete)
