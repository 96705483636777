import React from "react";
import {Container, Row} from "reactstrap";
import Header from "components/Headers/BlankHeader";

class LoadingContent extends React.Component {
  render() {
    return (
      <>
        <Header name={`Loading...`}/>
        <Container className="mt--7" fluid>
          <Row>
            Loading...
          </Row>
        </Container>
      </>
    );
  }
}

export default LoadingContent
