import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import {Redirect} from 'react-router-dom';
import _ from 'lodash';
import Toastr from 'toastr';
import AuthService from 'utils/AuthService.js';
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

const auth = new AuthService();

class Login extends React.Component {

  state = {
    is_auth_valid: false,
    username: '',
    password: '',
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    if (auth.loggedIn()) {
      console.log('already login');
      this.setState({is_auth_valid: true});
    }
  }

  handleChangeUsername = (obj) => {
    this.setState({username: obj.target.value});
  };

  handleChangePassword = (obj) => {
    this.setState({password: obj.target.value});
  };

  onSubmit(e) {
    e.preventDefault();
    Toastr.info('Validation....');
    auth
      .login(this.state.username, this.state.password)
      .then(res => {
        if (!auth.getProfile().isActive) {
          Toastr.warning("You're not active, please contact administrator");
          auth.logout();
        } else {
          Toastr.success('Welcome!');
          this.setState({is_auth_valid: true});
        }
      })
      // .catch(error => console.error(error))
      .catch(error => Toastr.error('Failed! Please check username & password'))
      .then(() => {
        // Toastr.info('Failed');
      });
  }

  render() {

    // console.log(this.state);

    if (this.state.is_auth_valid) {
      return <Redirect to={`/admin/dashboard`}/>
    }

    let buttonSubmitDisabled = true;
    if (!_.isEmpty(this.state.username) && !_.isEmpty(this.state.password)) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <AuthHeader
          title="Login"
          // lead="Use these awesome forms to login or create new account in your project for free."
        />
        <Container className="mt--8 pb-3">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Sign in with credentials</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      // className={classnames("mb-3", {
                      //   focused: this.state.focusedEmail
                      // })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Username"
                          type="text"
                          value={this.state.username}
                          onChange={this.handleChangeUsername}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          value={this.state.password}
                          onChange={this.handleChangePassword}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        disabled={buttonSubmitDisabled}
                        onClick={this.onSubmit}
                        className="my-4" color="info" type="button">
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {/*<Row className="mt-3">*/}
              {/*  <Col xs="6">*/}
              {/*    <a*/}
              {/*      className="text-light"*/}
              {/*      href="#pablo"*/}
              {/*    >*/}
              {/*      <small>Forgot password?</small>*/}
              {/*    </a>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
