/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Toastr from "toastr";
import AuthService from 'utils/AuthService.js';

const auth = new AuthService();

class AdminNavbar extends React.Component {

  state = {
    alert: null,
    isLogout: false,
  };

  logoutEventSweetAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{
            display: "block",
            // marginTop: "-100px",
          }}
          title="Keluar"
          onConfirm={() => this.onConfirm()}
          onCancel={() => this.onCancel()}
          confirmBtnCssClass="btn-danger"
          cancelBtnBsStyle="btn-secondary"
          confirmBtnText="Ya, keluar"
          cancelBtnText="Batal"
          showCancel
          btnSize=""
        >
          Anda yakin akan keluar dari aplikasi?
        </ReactBSAlert>
      )
    });
  };

  onCancel = () => {
    this.setState({
      alert: null,
      isLogout: false
    });
  }

  onConfirm = () => {
    Toastr.warning('Your session ended...');
    this.setState({isLogout: true});
    auth.logout();
  }

  render() {
    // console.log(this.state)

    if (this.state.isLogout) {
      return <Redirect to="/auth/login"/>
    }

    return (
      <>
        {this.state.alert}
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            {"navbar-dark bg-info": this.props.theme === "dark"},
            {"navbar-light bg-secondary": this.props.theme === "light"}
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt={auth.getProfile().fullName}
                          src={require("assets/img/theme/default-avatar.png")}
                        />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {auth.getProfile().fullName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem className="btn-link">
                      <Link to={`/admin/profile`}>
                        <i className="ni ni-single-02"/> <span>Profile</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="btn-link">
                      <Link to={`/admin/change-password`}>
                        <i className="ni ni-key-25"/> <span>Password</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider/>

                    <DropdownItem
                      className="btn-link"
                      href="#Logout"
                      onClick={() =>
                        this.setState({modalChange: false}, () =>
                          this.logoutEventSweetAlert()
                        )
                      }
                    ><i className="ni ni-user-run"/><span>Keluar</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {
  },
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
