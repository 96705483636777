import React, {Component} from 'react';
import _ from "lodash";
import {Table} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import DatetimeFormat from "../Utils/DatetimeFormat";
import DatetimeFormatV2 from "../Utils/DatetimeFormatV2";
import {ReadFileAttachment} from "../ReadFileAttachment";

class DataSuratPpk extends Component {

  render() {

    if (_.isNull(this.props.data)) {
      return <>loading...</>;
    }

    return (
      <>
        <Table bordered responsive>
          <tbody>
          <tr>
            <td>Nomer Surat :</td>
            <td>
              <h2><code>{this.props.data.nomerSurat}</code></h2>
            </td>
          </tr>
          <tr>
            <td>Tanggal Surat :</td>
            <td>{_.isNull(this.props.data.tanggalSurat) ? '-' :
              <DatetimeFormatV2 value={this.props.data.tanggalSurat}/>}</td>
          </tr>
          <tr>
            <td>Kode Hal :</td>
            <td>{_.has(this.props.data, 'kodeHalPpk') ? `[${this.props.data.kodeHalPpk.code}] ${this.props.data.kodeHalPpk.name}` : null}</td>
          </tr>
          <tr>
            <td>Kegiatan :</td>
            <td>{_.has(this.props.data, 'kegiatan') ? `[${this.props.data.kegiatan.code}] ${this.props.data.kegiatan.name}` : null}</td>
          </tr>
          <tr>
            <td>Pecipta :</td>
            <td>{_.has(this.props.data, 'createdBy') ? `[${this.props.data.createdBy.username}] ${this.props.data.createdBy.fullName}` : null}</td>
          </tr>
          <tr>
            <td>Perihal :</td>
            <td>{ReactHtmlParser(this.props.data.perihal)}</td>
          </tr>
          <tr>
            <td>File lampiran :</td>
            <td>{_.isNull(this.props.data.fileName) || _.isEmpty(this.props.data.fileName) ? null : <ReadFileAttachment token={this.props.token} data={this.props.data} /> }</td>
          </tr>
          <tr>
            <td>Date (create / update) :</td>
            <td>
              <small>
                <DatetimeFormat
                  value={this.props.data.createdAt}/> / {_.isEqual(this.props.data.createdAt, this.props.data.updatedAt) ? null :
                <DatetimeFormat value={this.props.data.updatedAt}/>}
              </small>
            </td>
          </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

DataSuratPpk.propTypes = {};

export default DataSuratPpk;
