import React, {Component} from 'react';
import _ from 'lodash';

class IconBoolean extends Component {

  render() {

    let result = null;
    if (_.isBoolean(this.props.value)) {
      result = <i className="fa fa-minus-circle"/>;
      if (this.props.value) {
        result = <i className="fa fa-check"/>
      }
    }

    return (
      <>{result}</>
    );
  }
}

export default IconBoolean;
