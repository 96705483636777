import _ from "lodash";

export function isAllowDocumentUpdateOrDelete(params) {
  // console.log(params);
  let result = false;

  if (_.has(params.data, 'createdBy')) {
    if (_.isEqual(params.data.createdBy.username, params.profile.username)) {
      result = true;
    }
  }

  if (_.isObject(_.find(params.profile.groups, {code: 'admin'}))) {
    result = true;
  }

  return result;
}
