import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import Switch from "react-switch";
import _ from "lodash";
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";

class KegiatanEdit extends React.Component {

  state = {
    is_update_data: false,
    is_valid_data: true,

    _data: null,

    code: '',
    code_exist: false,
    code_alias: '',
    name: '',
    description: '',
    is_active: true,
  };

  constructor(props) {
    super(props);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangeCodeAlias = this.handleChangeCodeAlias.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // console.log(this.props);
    this._getData(this.props.match.params.id);
  }

  _getData(id) {
    Axios.get(`/arsip-surat-ppk/kegiatan/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({
          _data: res.data,

          id: res.data.id,
          code: res.data.code,
          code_alias: _.isNull(res.data.codeAlias) ? '' : res.data.codeAlias,
          name: res.data.name,
          description: _.isNull(res.data.description) ? '' : res.data.description,
          is_active: res.data.isActive,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  _codeValidation = (code) => {
    Axios.get(`/is-kegiatan-surat-exist/${code}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data.result)
        this.setState({
          code_exist: res.data.result,
          is_valid_data: !res.data.result
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeCode = (obj) => {
    let code = obj.target.value;
    this.setState({code});

    if (code.length >= 2) {
      this._codeValidation(code);
    }
  };

  handleChangeCodeAlias = (obj) => {
    this.setState({code_alias: obj.target.value});
  };

  handleChangeName = (obj) => {
    this.setState({name: obj.target.value});
  };

  handleChangeDescription = (e) => {
    this.setState({description: e.target.value});
  };

  handleChangeIsActive(is_active) {
    this.setState({is_active});
  }

  onSubmit = () => {
    Toastr.info('Submit data');
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'PATCH',
      url: `/arsip-surat-ppk/kegiatan/${this.state.id}`,
      data: {
        code: this.state.code,
        codeAlias: this.state.code_alias,
        name: this.state.name,
        isActive: this.state.is_active ? 'true' : 'false',
        description: this.state.description,
      }
    })
      .then(res => {
        console.log(res.data);
        Toastr.success('Success!');
        this.setState({is_update_data: true});
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
        // swal('Error!', 'Unable save data.', 'error')
      })
  };

  render() {
    console.log(this.state);

    if (this.state.is_update_data) {
      return <Redirect push to={`/admin/kegiatan`}/>
    }

    let buttonSubmitDisabled = true;
    if (this.state.is_valid_data) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <Header name={`Kegiatan / Edit`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/kegiatan`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Code
                            </label>
                            <Input
                              readOnly
                              required
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.code}
                              onChange={this.handleChangeCode}
                              placeholder="Code"
                              type="text"
                            />
                            {this.state.code_exist ?
                              <FormText color={'danger'}>Oh noes! that name is already taken</FormText> : null}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Code Alias
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.code_alias}
                              onChange={this.handleChangeCodeAlias}
                              placeholder="Code Alias"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.name}
                              onChange={this.handleChangeName}
                              placeholder="Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Status Active : </label> <Switch
                        onChange={this.handleChangeIsActive}
                        checked={this.state.is_active}
                      />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          required
                          className="form-control-alternative"
                          minLength={3}
                          value={this.state.description}
                          onChange={this.handleChangeDescription}
                          placeholder="Description"
                          type="text"
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    color='primary'
                    disabled={buttonSubmitDisabled}
                    onClick={this.onSubmit}
                  >Save</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(KegiatanEdit)
