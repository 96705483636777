import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Container, ModalFooter, Row} from "reactstrap";
import {Link} from "react-router-dom";
import _ from 'lodash';
import 'moment/locale/id';
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";
import DataSuratUlpFormDetail from "../../components/PopUp/DataSuratUlp";
import LoadingContent from "../../components/Utils/LoadingContent";

class DataSuratUlpDetail extends React.Component {

  state = {
    data: null,
  };

  componentWillMount() {
    // console.log(this.props.match.params.id);
    this.getData(this.props.match.params.id);
  }

  getData = (id) => {
    Axios.get(`/arsip-surat-ppk/data-surat/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({data: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {

    if (_.isNull(this.state.data)) {
      return <LoadingContent/>
    }

    return (
      <>
        <Header name={`Data Surat PPK / Detail`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                </CardHeader>
                <CardBody>
                  <DataSuratUlpFormDetail token={this.props.token} data={this.state.data}/>
                </CardBody>
                <CardFooter>
                  <ModalFooter>
                    <Link to={`/admin/data-surat-ulp`}>
                      <Button color="default">Data Grid</Button>
                    </Link>
                    <Link to={`/admin/data-surat-ulp-edit/${this.state.data.id}`}>
                      <Button color="info">Edit</Button>
                    </Link>
                  </ModalFooter>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(DataSuratUlpDetail)
