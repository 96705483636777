import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import _ from 'lodash';
import Switch from "react-switch";
import Select from 'react-select';
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";

class UsersEdit extends React.Component {

  state = {
    id: '',
    username: '',
    password: '',
    full_name: '',
    group: null,
    kegiatan: null,
    is_active: true,

    group_options: null,
    kegiatan_options: null,

    is_saved_data: false,
    is_full_name_valid: true,
    is_group_options_show: false,
  };

  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeKegiatan = this.handleChangeKegiatan.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // console.log(this.props);
    this.getData(this.props.match.params.id);
    this.groupOptions();
    this.kegiatanOptions();
  }

  getData(id) {
    Axios.get(`/arsip-surat-ppk/users/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({
          id: res.data.id,
          username: res.data.username,
          full_name: res.data.fullName,
          is_active: res.data.isActive,
          group: _.isNull(res.data.arsipSuratPpkGroup) ? null : {
            value: res.data.arsipSuratPpkGroup.code,
            label: res.data.arsipSuratPpkGroup.name,
          },
        });


        if (!_.isNull(res.data.arsipSuratPpkGroup)) {
          if (_.isEqual(res.data.arsipSuratPpkGroup.code, 'arsip-surat-ppk-ppk')) {
            this.setState({is_group_options_show: true});
          }
        }

        // setup kegiatan
        if (_.isArray(res.data.kegiatan)) {
          const kegiatan = [];
          res.data.kegiatan.map((d, i) => {
            if (!_.isNull(d.uid)) {
              kegiatan.push({
                value: d.code,
                label: `[${d.code}] ${d.name}`,
              })
            }
            return kegiatan;
          });
          this.setState({kegiatan});
        }


      })
      .catch(err => {
        console.error(err);
      });
  }

  groupOptions = () => {
    Axios.get(`/arsip-surat-ppk/groups-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({group_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  kegiatanOptions = () => {
    Axios.get(`/arsip-surat-ppk/kegiatan-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({kegiatan_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangePassword = (obj) => {
    let password = obj.target.value;
    this.setState({password});

    if (password.length >= 3) {
      this.setState({is_password_valid: true});
    } else {
      this.setState({is_password_valid: false});
    }
  };

  handleChangeFullName = (obj) => {
    let full_name = obj.target.value;
    this.setState({full_name});
    // validation
    if (full_name.length >= 3) {
      this.setState({is_full_name_valid: true});
    } else {
      this.setState({is_full_name_valid: false});
    }
  };

  handleChangePassword = (e) => {
    this.setState({password: e.target.value});
  };

  handleChangeIsActive(is_active) {
    this.setState({is_active});
  }

  handleChangeGroup = (group) => {
    this.setState({group});
    // console.log(group);
    if (_.isEqual(group.value, 'arsip-surat-ppk-ppk')) {
      this.setState({is_group_options_show: true});
    } else {
      this.setState({is_group_options_show: false});
    }
  };

  handleChangeKegiatan = (kegiatan) => {
    this.setState({kegiatan});
  };

  onSubmit = () => {
    Toastr.info('Save data');

    let data = {
      username: this.state.username,
      fullName: this.state.full_name,
      isActive: this.state.is_active ? 'true' : 'false',
    }

    if (_.isObject(this.state.group)) {
      // group
      _.merge(data, {group: this.state.group.value});
      // kegiatan
      if (_.isEqual(this.state.group.value, 'arsip-surat-ppk-ppk')) {
        if (_.isArray(this.state.kegiatan)) {
          let kegiatanString = "";
          this.state.kegiatan.map((d, i) => {
            kegiatanString += `${(d.value)}`;
            if (i < (this.state.kegiatan.length - 1)) {
              kegiatanString += ',';
            }
            return _.merge(data, {kegiatan: `[${kegiatanString}]`});
          });
        }
      }
    }

    if (!_.isEmpty(this.state.password)) {
      _.merge(data, {password: this.state.password})
    }

    console.log(data);

    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'PATCH',
      url: `/arsip-surat-ppk/users/${this.state.id}`,
      data: data
    })
      .then(res => {
        console.log(res.data);
        Toastr.success('Success!');
        this.setState({is_saved_data: true});
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  render() {
    console.log(this.state);

    if (this.state.is_saved_data) {
      return <Redirect push to={`/admin/users`}/>
    }

    let buttonSubmitDisabled = true;
    if (
      this.state.is_full_name_valid &&
      _.isObject(this.state.group)) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <Header name={`Users / Add`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/users`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              readOnly
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.username}
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name (Nama Pejabat)
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              minLength={3}
                              value={this.state.full_name}
                              onChange={this.handleChangeFullName}
                              placeholder="Name (Nama Pejabat)"
                              type="text"
                            />
                            <FormText>min 3 character</FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          minLength={3}
                          value={this.state.password}
                          onChange={this.handleChangePassword}
                          placeholder="Password"
                          type="text"
                        />
                        <FormText color={'info'}>leave blank unless you want to change new password.</FormText>
                      </FormGroup>
                    </Col>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Status Active : </label>
                        <Switch
                          onChange={this.handleChangeIsActive}
                          checked={this.state.is_active}
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Group</label>
                        <Select
                          value={this.state.group}
                          onChange={this.handleChangeGroup}
                          options={this.state.group_options}
                        />
                      </FormGroup>
                    </div>
                    {(this.state.is_group_options_show) ?
                      <>
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Kegiatan</label>
                            <Select
                              isMulti
                              value={this.state.kegiatan}
                              onChange={this.handleChangeKegiatan}
                              options={this.state.kegiatan_options}
                            />
                            <FormText>Jika group terpilih adalah group PPK</FormText>
                          </FormGroup>
                        </div>
                      </> : null}
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    color='primary'
                    disabled={buttonSubmitDisabled}
                    onClick={this.onSubmit}
                  >Save</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(UsersEdit)
