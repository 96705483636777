import React from "react";
import {Card, CardBody, CardHeader, CardText, Col, Container, Row} from "reactstrap";
// core components
import WithAuth from "utils/WithAuth";
import CardsHeader from "components/Headers/CardsHeader.js";

class Dashboard extends React.Component {

  componentWillMount() {
    console.log(this.props);
  }

  render() {
    return (
      <>
        <CardsHeader name="Dashboard"/>
        <Container className="mt--6" fluid>
          <Row className="card-wrapper">
            <Col lg="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Dashboard</h5>
                </CardHeader>
                <CardBody>
                  <CardText className="mb-4">
                    Aplikasi Arsip Surat PPK
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default WithAuth(Dashboard)
