import React, {Component} from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, FormText, Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import 'moment/locale/id';
import Select from 'react-select';
import ReactDatetime from "react-datetime";
import ReactQuill from 'react-quill';
import _ from 'lodash';
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";
import {ReadFileAttachment} from "components/ReadFileAttachment";
import DataNotFound from "components/Utils/DataNotFound";
import {isAllowDocumentUpdateOrDelete} from "components/DocumentFunctions";
import Swal from "sweetalert2";

class DataSuratUlpEdit extends React.Component {

  state = {
    is_data_exist: false,
    is_preview: false,
    is_updated_data: false,
    is_valid_data: false,
    modal_preview: false,

    _data: null,

    id: '',
    order_surat: '',
    nomer_surat: '',
    tanggal_surat: null,
    kode_hal_ulp: null,
    tahun_periode: null,
    perihal: '',

    kode_hal_ulp_options: [],
    tahun_periode_options: [],
  };

  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeTanggalSurat = this.handleChangeTanggalSurat.bind(this);
    this.handleChangeTahunPeriode = this.handleChangeTahunPeriode.bind(this);
    this.handleChangeKodeHalUlp = this.handleChangeKodeHalUlp.bind(this);
    this.handleChangePerihal = this.handleChangePerihal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.toggleModalRoles = this.toggleModalRoles.bind(this);
  }

  componentWillMount() {
    console.log(this.props);
    this.tahunPeriodeOptions();
    this.kodeHalUlpOptions();
    this.getData(this.props.match.params.id);
  }

  getData = (id) => {
    Axios.get(`/arsip-surat-ppk/data-surat/${id}`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        // console.log(res.data)
        this.setState({
          _data: res.data,

          is_data_exist: true,
          id: res.data.id,
          order_surat: res.data.orderNomerSuratStrPad,
          tanggal_surat: _.isNull(res.data.tanggalSurat) ? null : Moment(res.data.tanggalSurat),
          tahun_periode: _.isNull(res.data.tahunPeriode) ? null : {
            value: res.data.tahunPeriode.code,
            label: `[${res.data.tahunPeriode.code}] ${res.data.tahunPeriode.name}`
          },
          kode_hal_ulp: _.isNull(res.data.kodeHalUlp) ? null : {
            value: res.data.kodeHalUlp.code,
            label: `[${res.data.kodeHalUlp.code}] ${res.data.kodeHalUlp.name}`
          },
          perihal: res.data.perihal,
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  tahunPeriodeOptions = () => {
    Axios.get(`/arsip-surat-ppk/tahun-periode-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({tahun_periode_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  kodeHalUlpOptions = () => {
    Axios.get(`/arsip-surat-ppk/kode-hal-ppk-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({kode_hal_ulp_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeTanggalSurat = tanggal_surat => {
    this.setState({tanggal_surat});
  };

  handleChangeTahunPeriode = (tahun_periode) => {
    this.setState({tahun_periode});
  };

  handleChangeKodeHalUlp = (kode_hal_ulp) => {
    this.setState({kode_hal_ulp});
  };

  handleChangePerihal = value => {
    this.setState({perihal: value});
  };

  onPreview = () => {
    this.setState({is_preview: true});
  }

  onSubmit = () => {
    Toastr.info('Update data');
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'PATCH',
      url: `/arsip-surat-ppk/data-surat/${this.state.id}`,
      data: {
        nomerSurat: `${this.state.order_surat}/${_.toUpper(this.props.profile.username)}/${_.toUpper(this.state.kode_hal_ulp.value)}/${_.toUpper(this.state.tahun_periode.value)}`,
        // tahunPeriode: _.isObject(this.state.tahun_periode) ? this.state.tahun_periode.value : null,
        tanggalSurat: _.isObject(this.state.tanggal_surat) ? this.state.tanggal_surat.format('YYYY-MM-DD') : null,
        kodeHalUlp: _.isObject(this.state.kode_hal_ulp) ? this.state.kode_hal_ulp.value : null,
        perihal: this.state.perihal,
      }
    })
      .then(res => {
        console.log(res.data);
        Toastr.success('Success!');
        this.setState({
          // new_data_uuid: res.data.detail,
          is_updated_data: true,
        });
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  toggleModalRoles() {
    this.setState({is_preview: !this.state.is_preview});
  }

  render() {

    // console.log(this.props.profile);
    console.log(this.state);

    if (!this.state.is_data_exist) {
      return <DataNotFound/>
    }

    if (this.state.is_updated_data) {
      return <Redirect push to={`/admin/data-surat-ulp`}/>
    }

    let buttonSubmitDisabled = true;
    if (
      _.isObject(this.state.tahun_periode)
      && _.isObject(this.state.kode_hal_ulp)
    ) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <Header name={`Data Surat ULP / Edit`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/data-surat-ulp`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nomer Surat
                            </label>
                            <h2>
                              {`${this.state.order_surat}/${_.toUpper(this.props.profile.username)}/${_.isNull(this.state.kode_hal_ulp) ? '-' : _.toUpper(this.state.kode_hal_ulp.value)}/${_.isNull(this.state.tahun_periode) ? '-' : _.toUpper(this.state.tahun_periode.value)}`}
                            </h2>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Tanggal Surat
                            </label>
                            <ReactDatetime
                              value={this.state.tanggal_surat}
                              locale={'id'}
                              onChange={this.handleChangeTanggalSurat}
                              inputProps={{
                                placeholder: "Date Picker Here",
                                // disabled: true,
                              }}
                              timeFormat={false}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Tahun (Periode)</label>
                        <Select
                          isDisabled={true}
                          value={this.state.tahun_periode}
                          onChange={this.handleChangeTahunPeriode}
                          options={this.state.tahun_periode_options}
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Kode Hal</label>
                        <Select
                          value={this.state.kode_hal_ulp}
                          onChange={this.handleChangeKodeHalUlp}
                          options={this.state.kode_hal_ulp_options}
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Perihal</label>
                        <ReactQuill
                          value={this.state.perihal}
                          onChange={this.handleChangePerihal}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["link", "blockquote", "code", "image"],
                              [
                                {
                                  list: "ordered"
                                },
                                {
                                  list: "bullet"
                                }
                              ]
                            ]
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>File lampiran</label>
                      {!_.isNull(this.state._data) ?
                        <FilesContainer
                          token={this.props.token}
                          dataSurat={{
                            id: this.state._data.id,
                            fileName: this.state._data.fileName,
                            fileType: this.state._data.fileType,
                            fileSize: this.state._data.fileSize,
                          }}
                          isEditable={isAllowDocumentUpdateOrDelete({
                            profile: this.props.profile,
                            data: this.state._data,
                          })}
                        /> : 'Loading...'}
                    </FormGroup>
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    color='primary'
                    disabled={buttonSubmitDisabled}
                    onClick={this.onPreview}
                  >Preview</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          size={'lg'}
          isOpen={this.state.is_preview}
          toggle={this.toggleModalRoles}>
          <ModalHeader toggle={this.toggleModalRoles}>Preview</ModalHeader>
          <ModalBody>
            <Table>
              <tbody>
              <tr>
                <td>Nomer Surat</td>
                <td>
                  <h2>
                    {`${this.state.order_surat}/${_.toUpper(this.props.profile.username)}/${_.isNull(this.state.kode_hal_ulp) ? '-' : _.toUpper(this.state.kode_hal_ulp.value)}/${_.isNull(this.state.tahun_periode) ? '-' : _.toUpper(this.state.tahun_periode.value)}`}
                  </h2>
                </td>
              </tr>
              <tr>
                <td>Tanggal Surat</td>
                <td>{_.isObject(this.state.tanggal_surat) ? this.state.tanggal_surat.format('LL') : null}</td>
              </tr>
              <tr>
                <td>Tahun (Periode)</td>
                <td>{_.isObject(this.state.tahun_periode) ? this.state.tahun_periode.label : null}</td>
              </tr>
              <tr>
                <td>Kode Hal</td>
                <td>{_.isObject(this.state.kode_hal_ulp) ? this.state.kode_hal_ulp.label : null}</td>
              </tr>
              <tr>
                <td>Perihal</td>
                <td>
                  <div className="react-html-parser">{ReactHtmlParser(this.state.perihal)}</div>
                </td>
              </tr>
              <div className="pl-lg-4">
                <FormGroup>
                  <label>File lampiran</label>
                  {!_.isNull(this.state._data) ?
                    <FilesContainer
                      token={this.props.token}
                      dataSurat={{
                        id: this.state._data.id,
                        fileName: this.state._data.fileName,
                        fileType: this.state._data.fileType,
                        fileSize: this.state._data.fileSize,
                      }}
                      isEditable={isAllowDocumentUpdateOrDelete({
                        profile: this.props.profile,
                        data: this.state._data,
                      })}
                    /> : 'Loading...'}
                </FormGroup>
              </div>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.onSubmit}>Saved</Button>
            <Button
              color="secondary"
              onClick={this.toggleModalRoles}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default WithAuth(DataSuratUlpEdit)

class FilesContainer extends Component {

  state = {
    is_uploaded: false,
    is_file_valid: false,
    data: null,
    file: null,
  };

  constructor(props) {
    super(props);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // console.log(this.props); // surat_uid
    this.setDataSurat(this.props.dataSurat);
  }

  setDataSurat = (data) => {
    this.setState({data});
  }

  refreshDataSurat(uid) {
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'GET',
      url: `/arsip-surat-ppk/data-surat/${uid}`,
    })
      .then(res => {
        this.setState({
          data: {
            id: res.data.id,
            fileName: res.data.fileName,
            fileType: res.data.fileType,
            fileSize: res.data.fileSize,
          }
        });
      })
      .catch(err => {
        console.error('ERROR!', err);
      })
  }

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeFile = (get_file) => {

    if (_.isObject(get_file)) {
      if (get_file.target.files.length > 0) {
        let file = get_file.target.files[0];
        let is_file_valid = false;

        if (_.isEqual(file.type, 'application/pdf') && (file.size / 1024 / 1024) < 5) {
          is_file_valid = true;
        }

        this.setState({file, is_file_valid});
      }
    }
  };

  onSubmit = () => {
    let formData = new FormData();
    formData.append('surat_uid', this.props.dataSurat.id);
    formData.append('file', this.state.file, this.state.file.name);

    Axios({
      method: 'POST',
      url: `/arsip-surat-ppk/file-attachment-upload`,
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      },
      data: formData,
    })
      .then(res => {
        // console.log(res.data);
        Toastr.success('Unggah file sukses!');
        this.refreshDataSurat(this.props.dataSurat.id);
        this.setState({file: null, is_file_valid: false});
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  handleDelete = () => {
    console.log('trigger from handleDelete');
    this.refreshDataSurat(this.props.dataSurat.id);
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Form
          onSubmit={this.handleOnSubmit}
        >
          <div className="pl-lg-4">
            <FormGroup>
              <Table bordered={true}>
                <tbody>
                <tr>
                  <td>
                    <ReadFileAttachment
                      token={this.props.token}
                      data={this.state.data}
                    />
                  </td>
                  <td>
                    {this.props.isEditable && !_.isEmpty(this.state.data.fileName) ? <DeleteFile
                      token={this.props.token}
                      onDelete={this.handleDelete}
                      data={this.state.data}/> : null}
                  </td>
                </tr>
                </tbody>
              </Table>
            </FormGroup>
          </div>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Input
                    disabled={!this.props.isEditable}
                    required
                    type="file"
                    onChange={this.handleChangeFile}
                    placeholder="Select File"
                  />
                  <FormText>format file yang diizinkan : pdf, max 5 MB</FormText>
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup>
                  <Button
                    color='info'
                    disabled={!this.state.is_file_valid}
                    onClick={this.onSubmit}
                  >Upload File</Button>
                </FormGroup>
              </Col>
            </Row>
            <ShowFile
              data={this.state.file}
              isFileValid={this.state.is_file_valid}
              isEditable={this.props.isEditable}
            />
          </div>
        </Form>

      </>
    )
  }
}

class ShowFile extends Component {
  render() {
    // console.log(this.props.data);
    if (_.isNull(this.props.data)) {
      return <></>
    }
    return (
      <>
        <Alert color={this.props.isFileValid ? 'success' : 'danger'}>
          File Name : {this.props.data.name} <br/>
          File Size : {(this.props.data.size / 1024 / 1024)} MB <br/>
          {this.props.isFileValid ? 'File is valid' : 'File not valid'}
        </Alert>
      </>
    );
  }
}

class DeleteFile extends Component {

  onSubmit = () => {
    Swal.fire({
      title: `${this.props.data.fileName} akan dihapus!. Anda yakin?`,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus"
    }).then(result => {

      // console.log(result.value);

      if (result.value) {
        Axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`,
            // 'Content-Type': 'multipart/form-data',
            'Accept': 'application/hal+json',
          },
          method: 'DELETE',
          url: `/arsip-surat-ppk/file-attachment-remove`,
          data: {
            surat_uid: this.props.data.id,
          }
        })
          .then(res => {
            console.log(res.data);
            Toastr.success(`${this.props.data.fileName} berhasil dihapus!`);
            this.props.onDelete();
          })
          .catch(err => {
            console.error('ERROR!', err);
            Toastr.error('Failed!');
          })
      }
    });
  };

  render() {
    // console.log(this.props);

    if (_.isNull(this.props.data)) {
      return <></>
    }

    return (
      <>
        <i
          className="fa fa-trash"
          onClick={this.onSubmit}
        />
      </>
    );
  }
}
