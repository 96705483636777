import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import Toastr from 'toastr';
import ReactHtmlParser from 'react-html-parser';
import 'moment/locale/id';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import Header from "components/Headers/SimpleHeader";
import WithAuth from "utils/WithAuth";
import Axios from "helpers/AxiosWithoutToken";
import ReactDatetime from "react-datetime";

class DataSuratUlpAdd extends React.Component {

  state = {
    is_preview: false,
    is_saved_data: false,
    is_valid_data: false,
    modal_preview: false,
    new_data_uuid: '',

    order_surat: '[AUTO-GENERATE]',
    tanggal_surat: null,
    kode_hal_ulp: null,
    tahun_periode: null,
    perihal: '',

    kode_hal_ulp_options: [],
    tahun_periode_options: [],

  };

  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeTanggalSurat = this.handleChangeTanggalSurat.bind(this);
    this.handleChangeTahunPeriode = this.handleChangeTahunPeriode.bind(this);
    this.handleChangeKodeHalUlp = this.handleChangeKodeHalUlp.bind(this);
    this.handleChangePerihal = this.handleChangePerihal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.toggleModalForm = this.toggleModalForm.bind(this);
  }

  componentWillMount() {
    // console.log(this.props);
    this.tahunPeriodeOptions();
    this.kodeHalUlpOptions();
  }

  tahunPeriodeOptions = () => {
    Axios.get(`/arsip-surat-ppk/tahun-periode-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({tahun_periode_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  kodeHalUlpOptions = () => {
    Axios.get(`/arsip-surat-ppk/kode-hal-ulp-list?format=select_options&is-active=true`, {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({kode_hal_ulp_options: res.data});
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleOnSubmit = event => {
    event.preventDefault();
  };

  handleChangeTanggalSurat = tanggal_surat => {
    this.setState({tanggal_surat});
  };

  handleChangeTahunPeriode = (tahun_periode) => {
    this.setState({tahun_periode});
  };

  handleChangeKodeHalUlp = (kode_hal_ulp) => {
    this.setState({kode_hal_ulp});
  };

  handleChangePerihal = value => {
    this.setState({perihal: value});
  };

  onPreview = () => {
    this.setState({is_preview: true});
  }

  onSubmit = () => {
    Toastr.info('Save data');
    Axios({
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      method: 'POST',
      url: `/arsip-surat-ppk/data-surat`,
      data: {
        tahunPeriode: _.isObject(this.state.tahun_periode) ? this.state.tahun_periode.value : null,
        tanggalSurat: _.isObject(this.state.tanggal_surat) ? this.state.tanggal_surat.format('YYYY-MM-DD') : null,
        kodeHalUlp: _.isObject(this.state.kode_hal_ulp) ? this.state.kode_hal_ulp.value : null,
        perihal: this.state.perihal,
        namaPejabat: this.props.profile.fullName,
        isDraft: "true",
        group: this.props.profile.arsipSuratPpkGroup.code,
      }
    })
      .then(res => {
        console.log(res.data);
        Toastr.success('Success!');
        this.setState({
          new_data_uuid: res.data.detail,
          is_saved_data: true,
        });
      })
      .catch(err => {
        console.error('ERROR!', err);
        Toastr.error('Failed!');
      })
  };

  toggleModalForm() {
    this.setState({is_preview: !this.state.is_preview});
  }

  render() {

    // console.log(this.props.profile);
    // console.log(this.state);

    if (this.state.is_saved_data) {
      return <Redirect push to={`/admin/data-surat-ulp-detail/${this.state.new_data_uuid}`}/>
    }

    let buttonSubmitDisabled = true;
    if (
      _.isObject(this.state.tahun_periode)
      && _.isObject(this.state.kode_hal_ulp)
      && _.isObject(this.state.tanggal_surat)
    ) {
      buttonSubmitDisabled = false;
    }

    return (
      <>
        <Header name={`Data Surat ULP / Add`}/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Link to={`/admin/data-surat-ulp`}>
                    <Button className={'primary'}>Data</Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nomer Surat
                            </label>
                            <h2>
                              {`${this.state.order_surat}/${_.toUpper(this.props.profile.username)}/
                              ${_.isNull(this.state.kode_hal_ulp) ? '-' : _.toUpper(this.state.kode_hal_ulp.value)}/
                              ${_.isNull(this.state.tahun_periode) ? '-' : _.toUpper(this.state.tahun_periode.value)}`}
                            </h2>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Tanggal Surat
                            </label>
                            <ReactDatetime
                              locale={'id'}
                              onChange={this.handleChangeTanggalSurat}
                              inputProps={{
                                placeholder: "Date Picker Here"
                              }}
                              timeFormat={false}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Tahun (Periode)</label>
                        <Select
                          value={this.state.tahun_periode}
                          onChange={this.handleChangeTahunPeriode}
                          options={this.state.tahun_periode_options}
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Kode Hal</label>
                        <Select
                          value={this.state.kode_hal_ulp}
                          onChange={this.handleChangeKodeHalUlp}
                          options={this.state.kode_hal_ulp_options}
                        />
                      </FormGroup>
                    </div>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Perihal</label>
                        <ReactQuill
                          value={this.state.perihal}
                          onChange={this.handleChangePerihal}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["link", "blockquote", "code", "image"],
                              [
                                {
                                  list: "ordered"
                                },
                                {
                                  list: "bullet"
                                }
                              ]
                            ]
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    color='primary'
                    disabled={buttonSubmitDisabled}
                    onClick={this.onPreview}
                  >Preview</Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          size={'lg'}
          isOpen={this.state.is_preview}
          toggle={this.toggleModalForm}>
          <ModalHeader toggle={this.toggleModalForm}>Preview</ModalHeader>
          <ModalBody>
            <Table>
              <tbody>
              <tr>
                <td>Nomer Surat</td>
                <td>
                  <h2>
                    {`${this.state.order_surat}/${_.toUpper(this.props.profile.username)}/
                    ${_.isNull(this.state.kode_hal_ulp) ? '-' : _.toUpper(this.state.kode_hal_ulp.value)}/
                    ${_.isNull(this.state.tahun_periode) ? '-' : _.toUpper(this.state.tahun_periode.value)}`}
                  </h2>
                </td>
              </tr>
              <tr>
                <td>Tanggal Surat</td>
                <td>[AUTO-GENERATE]</td>
              </tr>
              <tr>
                <td>Tahun (Periode)</td>
                <td>{_.isObject(this.state.tahun_periode) ? this.state.tahun_periode.label : null}</td>
              </tr>
              <tr>
                <td>Kode Hal</td>
                <td>{_.isObject(this.state.kode_hal_ulp) ? this.state.kode_hal_ulp.label : null}</td>
              </tr>
              <tr>
                <td>Perihal</td>
                <td>
                  <div className="react-html-parser">{ReactHtmlParser(this.state.perihal)}</div>
                </td>
              </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.onSubmit}>Saved</Button>
            <Button
              color="secondary"
              onClick={this.toggleModalForm}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default WithAuth(DataSuratUlpAdd)
